<template>
  <div class=" user-details">
    <div class="">
      <div class="profile-header profile-bg">
        <div class="row">
          <div class="col-md-2 text-right hovereffect pl-5 pr-0 mr-0">
            <img style="height:100%;width:100%;" :src="profileImg" alt="User Avatar" :key="updateLoggedUserInfo" class="mr-3 avatar">
          </div>
          <div class="col-md-6">
            <div class="profiler-detail pl-4 pr-4">
              <h3>{{userProfileData.full_name}}</h3>
              <div class="d-flex">
                <div class="left mr-5">
                  <h5>{{userProfileData.membership.name}}</h5>
                </div>
                <div class="right" style="margin-left:auto;align-items: center;">
                  <h5 class="text-white text-right d-flex" style="align-items: center;">
                    <span class="lable text-white mr-2">Membership# </span>
                    {{userProfileData.confirmation_code || "N/A"}}
                  </h5>
                </div>
              </div>
              <div class="b-der"></div>
              <div class="d-flex">
                <div class="reg_date mt-3 mr-3">
                  <div class="d-flex align-items-center">
                    <div class="up_icon mr-4">
                      <img src="/dist/img/profile/ico-registerd-date.png">
                    </div>
                    <div class="up_text">
                      <span class="lable">Registered Date</span>
                      <span> {{ userProfileData.membership_startdate || "N/A"}}</span>
                    </div>
                  </div>
                </div>
                <div class="ex_date mt-3 ml-3">
                  <div class="d-flex align-items-center">
                    <div class="up_icon mr-4">
                      <img src="/dist/img/profile/ico-expired-date.png">
                    </div>
                    <div class="up_text">
                      <span  class="lable">Membership Expiration Date</span>
                      <span> {{userProfileData.membership_endtate || "N/A"}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- profile body start-->
      <div class="profile-body mt-4">
        <custom-alert v-if="displayAlert==true" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
        <div  class="row">
          <div  class="col-md-6 r-pad">
            <div class="card">
              <div class="card-header">
                <div class="header-title">
                  <h3 >Personal Information </h3>
                  
                </div>
                <div  class="modal-btn">
                  <b-button v-b-modal.modal-center1><span class="fa fa-edit"></span> Edit</b-button>
                </div>
              </div>
              <div class="card-body">
                <div  class="row">
                  <div  class="col-md-6 col-lg-4 ">
                    <div class="form-group">
                      <label class="form-label">First Name</label>
                      <span >{{userProfileData.first_name || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Middle Initial</label>
                      <span >{{userProfileData.middle_name || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label" >Last Name</label>
                      <span >{{userProfileData.last_name || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Birthday</label>
                      <span >{{userProfileData.dob || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Gender</label>
                      <span >{{userProfileData.gender || "N/A"}}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Affiliated Clubs/Teams</label>
                      <span class="title">{{ userProfileData.club.name || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">1st Choice</label>
                      <span class="title">{{ sportDisciplines[userProfileData.personal.first_choice] || "NP - No Preference" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">2nd Choice</label>
                      <span class="title">{{ sportDisciplines[userProfileData.personal.second_choice]  || "NP - No Preference"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Federation </label>
                      <span >{{userProfileData.personal.home_federation || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Skier License</label>
                      <span >{{userProfileData.personal.skier_license || "N/A"}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-3">
              <div class="card-header ">
                <div class="header-title">
                  <h3 >Membership Information</h3>
                </div>
                <div  class="modal-btn">
                  <b-button v-b-modal.modalMembership><span class="fa fa-edit"></span> Edit</b-button>
                </div>
              </div>
              <div class="card-body">
                <div  class="row">
                  <div  class="col-md-6 col-lg-4 ">
                    <div class="form-group">
                      <label class="form-label">Membership #</label>
                      <span>{{userProfileData.confirmation_code || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Membership Status</label>
                      <span class="text-success" v-if="userProfileData.membership_status_text == 'Current'"><img class="mr-1" src="/dist/img/profile/ico-status-current.png">  {{userProfileData.membership_status_text || "N/A"}}</span>
                      <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{userProfileData.membership_status_text || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label" >Safe Sport Training Status</label>
                      <span class="text-success" v-if="userProfileData.safe_sports_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{userProfileData.safe_sports_status_text || "N/A"}}</span>
                      <span class="text-warning" v-else><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{userProfileData.safe_sports_status_text || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Background Screen Status</label>
                      <span class="text-warning"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Pending</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div  class="col-md-6 l-pad">
            <div class="card">
              <div class="card-header">
                <div class="header-title">
                  <h3 >Address Information</h3>
                </div>
                <div  class="modal-btn">
                  <b-button v-b-modal.modal-center><span class="fa fa-edit"></span> Edit</b-button>
                </div>
              </div>
              <div class="card-body">
                <div  class="row">
                  <div  class="col-md-6 col-lg-4 ">
                    <div class="form-group">
                      <label class="form-label">Mailling Address</label>
                      <span >{{userProfileData.address1 || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Address2</label>
                      <span >{{userProfileData.address2 || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">City</label>
                      <span >{{userProfileData.city || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">State</label>
                      <span>{{userProfileData.state || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Postal Code</label>
                      <span >{{userProfileData.zip || "N/A"}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <div class="header-title">
                  <h3 >Contact Information</h3>
                </div>
                <div  class="modal-btn">
                  <b-button v-b-modal.modal-center3><span class="fa fa-edit"></span> Edit</b-button>
                </div>
              </div>
              <div class="card-body">
                <div  class="row">
                  <div  class="col-md-6 col-lg-4 ">
                    <div class="form-group">
                      <label class="form-label">Home Phone</label>
                      <span >{{userProfileData.phone_1 || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Mobile Phone</label>
                      <span >{{userProfileData.phone_2 || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Email</label>
                      <span ><a class="email-link" v-bind:href="'mailto:' + userProfileData.email" >{{userProfileData.email || "N/A"}}</a></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row profile-body " >
        <div class="col-md-12">
          <div class="card table-head Merchandise">
            <div class="card-header">
              <div class="header-title">
                <h3>Membership History</h3>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body pt-0 table-responsive">
              <b-table
                  id="my-table"
                  :items="userProfileData.registrationArr"
                  :fields="tblFields"
                  class="mt-3"
                  hover
                  show-empty
              >
                <template :fields="items">
                  <div class="text-left py-0 align-middle">
                    <div class="btn-group btn-group-sm">
                      <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                      <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                      </button>
                      <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                    </div>
                  </div>
                </template>
                <template #empty="scope">
                  <h4>{{ scope.emptyText }}</h4>
                </template>
                <template #cell(waiver)="row">
                  <div class="text-left py-0 align-middle">
                    <div class="btn-group btn-group-sm">
                      <button @click="downloadDocs(row.item.id, 'waiver')"
                              class="download-btn ml-1 mt-0"><img src="/dist/img/profile/ico-download.png"></button>
                    </div>
                  </div>
                </template>
                <template #cell(status)="row">
                  {{row.value==4 ? 'Paid' : 'Not Paid'}}
                </template>
                <template #cell(receipt)="row">
                  <div class="text-left py-0 align-middle">
                    <div class="btn-group btn-group-sm">
                      <button @click="downloadDocs(row.item.id, 'payment')"
                              class="download-btn ml-1 mt-0"><img src="/dist/img/profile/ico-download.png"></button>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <div class="row profile-body" v-show="userProfileData.registration.donation_amount>0">
        <div class="col-md-12">
          <div class="card table-head Merchandise">
            <div class="card-header">
              <div class="header-title">
                <h3>Donation</h3>
              </div>
            </div>
            <div class="card-body pt-0 table-responsive">
              <b-table
                  id="myDonationTable"
                  :items="userProfileData.registrationArr"
                  :fields="donationTbl"
                  class="mt-3"
                  hover
                  show-empty
              >
                <template #cell(donation_id)="row">
                  {{ listOfDonationSportDiscipline[row.value] }}
                </template>
                <template #cell(status)="row">
                  {{row.value==4 ? 'Paid' : 'Not Paid'}}
                </template>
              </b-table>
            </div>
          </div>

        </div>
      </div>
      <div class="row profile-body" >
        <div class="col-md-12">
          <div class="card table-head Merchandise mb-3">
            <div class="card-header">
              <div class="header-title">
                <h3>Merchandise</h3>
              </div>
            </div>
            <div class="card-body pt-0 table-responsive">
              <b-table
                  id="myMerchandiseTable"
                  :items="userProfileData.cartProducts"
                  :fields="merchandiseTbl"
                  class="mt-3"
                  hover
                  show-empty
              >
                <template #cell(registration.status)="row">
                  {{row.value==4 ? 'Paid' : 'Not Paid'}}
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-center1" size="lg" ok-title="Update" @ok="updateMemberInfo()" centered title="Personal Information">
      <div class="form-body">
        <form>
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label class="form-label" for="first_name">First Name</label>
                <input type="text" id="first_name" v-model="UpdateProfile.first_name" placeholder="John" class="form-control">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label class="form-label" for="middle_name">Middle Initial</label>
                <input type="text" id="middle_name" v-model="UpdateProfile.middle_name" value="" placeholder="A" maxlength="2" class="form-control">
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label class="form-label" for="last_name">Last Name</label>
                <input type="text" id="last_name" v-model="UpdateProfile.last_name" value="" placeholder="Doe" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="birthday" class="form-label">Birthday <sup class="text-danger">*</sup></label>
                <div class="input-group birthdate">
                  <div class="birthdaypicker">
                    <date-pick
                        v-model="UpdateProfile.dob"
                        :pickTime="false"
                        name="form.dob"
                        :format="'MM/DD/YYYY'"
                        :selectableYearRange="{from: 1900, to: 2021}"
                        v-mask="'##/##/####'"
                        :isDateDisabled="isFutureDate"
                        placeholder="MM/DD/YYYY"
                        :parseDate="parseDatePick"
                    ></date-pick>
                  </div>
                  <span class="text-sm text-danger">{{ errors.dob}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="clubandteam" class="form-label">Affiliated Clubs/Teams</label>
                <Select2 v-model="UpdateProfile.team_id" :options="clubOptions" @change="myChangeEvent($event)" @select="mySelectEvent($event)" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label" for="male">Gender</label><br>
                <div class="d-flex">
                  <div class="sexitems">
                    <input type="radio" v-model="UpdateProfile.gender" :checked="userProfileData.gender == 'Male'" id="male" name="gender" value="Male">
                    <label for="male">Male</label>
                  </div>
                  <div class="sexitems">
                    <input type="radio" v-model="UpdateProfile.gender" :checked="userProfileData.gender == 'Female'" id="female" name="gender" value="Female">
                    <label for="female">Female</label>
                  </div>
                  <div class="sexitems">
                    <input type="radio" v-model="UpdateProfile.gender" :checked="userProfileData.gender == 'Other'" id="other" name="gender" value="Other">
                    <label for="other">Other</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pr-0">
              <div class="form-group">
                <label for="first-choice" class="form-label">1st Choice <sup>*</sup></label>
                <select class="form-control" id="first-choice" name="form.first_choice"
                        v-model="UpdateProfile.first_choice">
                  <option value="">Select</option>
                  <option v-for="(discipline, index) in sportDisciplines" :value="index"
                          :key="'types'+index">{{ discipline }}
                  </option>
                </select>
                <span class="text-sm text-danger">{{ errors.first_choice }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="secondchoice" class="form-label">2nd Choice </label>
                <select class="form-control" id="secondchoice" name="form.second_choice"
                        v-model="UpdateProfile.second_choice">
                  <option value="">Select</option>
                  <option v-for="(discipline, index) in sportDisciplines" :value="index"
                          :key="'types'+index">{{ discipline }}
                  </option>
                </select>

                <span class="text-sm text-danger">{{ errors.second_choice }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="home_federation" class="form-label">Federation <sup>*</sup></label>
                <select class="form-control" id="home_federation" name="form.home_federation"
                        v-model="UpdateProfile.home_federation">
                  <option value="">Select</option>
                  <option v-for="(group, index) in groups" :key="'types'+index">{{ group.name }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.home_federation }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div v-if="UpdateProfile.home_federation !='USA'">
                <div class="form-group">
                  <label for="skier_license" class="form-label">Foreign Federation ID<sup>*</sup></label>
                  <input type="text" id="skier_license" class="form-control" maxlength="9"
                         placeholder="" name="form.skier_license"
                         v-model="UpdateProfile.skier_license"><br/>
                  <span class="text-danger font-13">FOR FOREIGN ATHLETES Use numbers only as listed in EMS Athlete Code DO NOT INCLUDE FEDERATION PREFIX</span>
                  <span class="text-sm text-danger">{{ errors.skier_license }}</span>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </b-modal>
    <b-modal id="modal-center3" size="lg" ok-title="Update" @ok="updateMemberInfo()" centered title="Contact Details">
      <div class="form-body">
        <form>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label" for="phone_1">Phone</label>
                <input type="text" id="phone_1" v-model="UpdateProfile.phone_1" value="" placeholder="8080 Holister" class="form-control">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label" for="phone_2">Alternative Phone</label>
                <input type="text" id="phone_2" v-model="UpdateProfile.phone_2" value="" placeholder="Apt.400" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label" for="email">Email</label>
                <input type="text" id="email" v-model="UpdateProfile.email" value="" placeholder="William.smith@email.com" class="form-control">
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal id="modal-center" size="lg" ok-title="Update" @ok="updateMemberInfo()" centered title="Address Details">
      <div class="form-body">
        <form>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label" for="address1">Mailling Address</label>
                <input type="text" id="address1" v-model="UpdateProfile.address1" value="" placeholder="8080 Holister" class="form-control">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label" for="address2">Address2</label>
                <input type="text" id="address2" v-model="UpdateProfile.address2" value="" placeholder="Apt.400" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="form-label" for="city">City</label>
                <input type="text" id="city" v-model="UpdateProfile.city" value="" placeholder="FL" class="form-control">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="form-label" for="state">State</label>
                <input type="text" id="state" v-model="UpdateProfile.state" value="" placeholder="FL" class="form-control">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="form-label" for="zip">Postal Code</label>
                <input type="text" id="zip" v-model="UpdateProfile.zip" value="" placeholder="77080" class="form-control">
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal id="modalMembership" size="lg" ok-title="Update" @ok="updateMemberInfo()" centered title="Membership Information">
      <form>
        <div class="row">
          <div  class="col-md-4">
            <div class="form-group">
              <label class="form-label">Membership Status</label>
              <select name="membership_stauts" id="membership_stauts" v-model="UpdateProfile.membership_status" class="form-control">
                <option value="">select</option>
                <option value="Current">Current</option>
                <option value="Expired">Expired</option>
                <option value="Waiver Needed">Waiver Needed</option>
                <option value="Pending">Pending</option>
                <option value="On Probation">On Probation</option>
                <option value="Suspended">Suspended</option>
              </select>
            </div>
          </div>
          <div  class="col-md-4">
            <div class="form-group">
              <label class="form-label">Safe Sport Training Status</label>
              <select name="safesports_status" id="safesports_status" v-model="UpdateProfile.safesports_status" class="form-control">
                <option value="">select</option>
                <option value="Expired">Expired</option>
                <option value="Complete">Complete</option>
                <option value="In Progress">In Progress</option>
                <option value="Not Started">Not Started</option>
              </select>
            </div>
          </div>
          <div  class="col-md-4">
            <div class="form-group">
              <label class="form-label">Background Screen Status</label>
              <select name="background_screen" id="background_screen" v-model="UpdateProfile.background_screen" class="form-control">
                <option value="">select</option>
                <option value="Active">Active</option>
                <option value="pending">Pending</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import DatePick from "vue-date-pick";
import Select2 from "v-select2-component";
import CustomAlert from "../CustomAlert";
import axios from "axios";
import moment from "moment";

export default {
  name: "familyMemberProfile",
  components: { DatePick, Select2, CustomAlert },
  data() {
    return {
      userProfileData: '',
      startDate: '',
      validThru: '',
      profileImg: '/dist/img/profile1.png',
      tblFields: [
        {key: 'member_ship.name', sortable: true, label: 'Registration Type', formatter: 'formatName'},
        {key: 'transaction_id', sortable: true, label: 'Transaction Id', formatter: 'formatName'},
        {key: 'transaction_date', sortable: true, label: 'Transaction Date', formatter: "usdate"},
        {key: 'status', sortable: true, label: 'Status', formatter: 'formatName'},
        {key: 'waiver', sortable: false, label: 'Waiver'},
        {key: 'receipt', sortable: false, label: 'Receipt'},
      ],
      donationTbl: [
        {key: 'donation_id', sortable: true, label: 'Donation Discipline', formatter: 'formatName'},
        {key: 'donation_amount', sortable: true, label: 'Amount', formatter: 'formatName'},
        {key: 'transaction_id', sortable: true, label: 'Transaction Id', formatter: 'formatName'},
        {key: 'transaction_date', sortable: true, label: 'Transaction Date', formatter: "usdate"},
        {key: 'status', sortable: true, label: 'Status'},
      ],
      merchandiseTbl:[
        {key: 'name', sortable: true, label: 'Product', formatter: 'formatName'},
        {key: 'price', sortable: true, formatter: 'formatName'},
        {key: 'quantity', sortable: true, formatter: 'formatName'},
        {key: 'total', sortable: true, formatter: 'formatName'},
        {key: 'registration.transaction_id', sortable: true, label: 'Transaction Id', formatter: 'formatName'},
        {key: 'registration.transaction_date', sortable: true, label: 'Transaction Date', formatter: "usdate"},
        {key: 'registration.status', sortable: true, label: 'Status'},
      ],
      currentMemberId:0,
      UpdateProfile:{
        "first_name": "",
        "last_name": "",
        "middle_name": "",
        "address1": "",
        "address2": "",
        "zip": "",
        "state": "",
        "state_code": "",
        "city": "",
        "email": "",
        "country": "",
        "phone_1": "",
        "phone_2": "",
        "dob": "",
        "gender": "",
        "citizenship":"",
        "team_id":"",
        "first_choice":"",
        "second_choice":"",
        "home_federation":"",
        "skier_license":"",
        "membership_status":"",
        "safesports_status":"",
        "registration_id":"",
        "background_screen":"pending",
      },
      clubOptions:[],
      errors: {},
      updateLoggedUserInfo:0,
    }
  },
  methods: {
    
    formatName(value) {
      return value || 'N/A'
    },
    userProfileApi() {
      this.memId = this.$route.params.memId;
      axios.get(this.basePath+"api/admin/family_member/"+this.memId,{ headers:this.adminHeaders })
          .then((response)=>{
            const resp  = response.data.data;
            this.userProfileData = resp;

            for (let prop in resp) {
              if ( Object.prototype.hasOwnProperty.call(this.UpdateProfile, prop) ) {
                this.UpdateProfile[prop] = resp[prop]
              }
            }
            for (let prop in resp.personal) {
              if ( Object.prototype.hasOwnProperty.call(this.UpdateProfile, prop) ) {
                this.UpdateProfile[prop] = resp.personal[prop]
              }
            }
            this.UpdateProfile.team_id = resp.club.id;
            this.profileImg = (resp.profile_pic!=null? process.env.VUE_APP_URL+resp.profile_pic:'/dist/img/profile1.png')+'?v='+moment().format('x');
            console.log(this.updateProfile)
          }).catch((error) =>{
        console.log(error);
      });
    },
    downloadDocs(item, type) {
      this.downloadPdf(this.basePath + 'api/registration/download/'+type+'/'+item, 'Reg'+item+"_"+type );
    },
    downloadPdf(url, name){
      axios.get(url, {headers:this.adminHeaders, responseType: 'arraybuffer'})
          .then(response => {
            let blob = new Blob([response.data], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.userProfileData.member_id+"_"+name+'.pdf';
            link.click();
            link.remove();
          }).catch((error)=>{
        console.log(error);
      });
    },
    updateMemberInfo(){
      this.displayAlert = false;
      axios.post(this.basePath + 'api/update/member/'+this.currentMemberId, this.UpdateProfile,{ headers:this.adminHeaders })
          .then(function (response) {
            this.alertMessage = "Member Information updated successfully";
            this.displayAlert = true;
            this.userProfileApi();
            this.updateLoggedUserInfo += 1;
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    getClubs(){
      axios.get(this.basePath + "api/get_teams")
          .then((response) => {
            var clubs = response.data.data;
            this.clubOptions = clubs;
          })
          .catch(error=>{
            console.log("error geting clubs list")
          })
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    myChangeEvent(val){
      this.UpdateProfile.team_id=val;
    },
    mySelectEvent({id, text}){
      this.UpdateProfile.team_id=id;
    },
  },
  mounted(){
    this.userProfileApi();
    this.getDiscipline(1);
    this.getClubs();
    this.getGroup();
  },
  computed: {}
}
</script>

<style scoped>

</style>